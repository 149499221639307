.ContainerSeta {
    padding-top: 10vh;
  }
  
  .chevron {
    position: absolute;
    width: 2.1rem;
    height: 0.48rem;
    opacity: 0;
    transform: scale(0.3);
    -webkit-animation: move-chevron 3s ease-out infinite;
            animation: move-chevron 3s ease-out infinite;
  }
  
  .chevron:first-child {
    -webkit-animation: move-chevron 3s ease-out 1s infinite;
            animation: move-chevron 3s ease-out 1s infinite;
  }
  
  .chevron:nth-child(2) {
    -webkit-animation: move-chevron 3s ease-out 2s infinite;
            animation: move-chevron 3s ease-out 2s infinite;
  }
  
  .chevron:before,
  .chevron:after {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 50%;
    background: var(--horizontal-gradient);
  }
  
  .chevron:before {
    left: -16px;
    width: 100%;
    transform: skewY(30deg);
  }
  
  .chevron:after {
    right: -16px;
    width: 100%;
    transform: skewY(-30deg);
  }
  
  @keyframes move-chevron {
    25% {
      opacity: 1;
    }
    33.3% {
      opacity: 1;
      transform: translateY(2.28rem);
    }
    66.6% {
      opacity: 1;
      transform: translateY(3.12rem);
    }
    100% {
      opacity: 0;
      transform: translateY(4.8rem) scale(0.5);
    }
  }