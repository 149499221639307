.containerTecno {
	gap: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 30px;
    border-bottom: solid 2px #ffffff30;
}

.containerTecnoImages {
	gap: 10px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
}

.subContainerTecno {
	width: 80px;
	padding: 10px;
	display: flex;
	align-items: center;
	flex-direction: column;
	border-radius: 10%;
	background: #ffffff20;
}
.subContainerTecno:hover {
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, #00ff00 0px 0px 0px 3px;
	background: #00ff0025;
	p {
		color: #00ff00
	}
}
.imageTecno {
	height: 60px;
	padding-bottom: .8rem;
}

.imageTecno > img {
	height: 100%;
	width: 100%;
	border-radius: 10%;
	transition: .5s ease-out;
	filter: drop-shadow(1px 1px 3px #000);
}

.textTecno > p {
	font-weight: bold;
	color: #fff;
	text-shadow: 1px 1px 3px #000;
}