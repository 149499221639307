.botaoRB {
    display: flex;
    height: 50px;
}

.colorido {
    position: relative;
    width: 100%;
    height: 100%;
    line-height: 48px;
    text-transform: uppercase;
    font-size: 20px;
    letter-spacing: 4px;
    background: linear-gradient(45deg, #fb0094, #00f, #0f0, #ff0, #f00, #fb0094, #00f, #0f0, #ff0, #f00);
    background-size: 400%;
    border-radius: 10px;
}


/* .colorido:hover {
    -webkit-box-reflect: below 1px linear-gradient(transparent, rgba(0, 0, 0, 0.065));
    border-radius: 16px;
} */

.colorido:hover::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(45deg, #fb0094, #00f, #0f0, #ff0, #f00, #fb0094, #00f, #0f0, #ff0, #f00);
        background-size: 300%;
        opacity: 0;
        transition: 0.5s;
        animation: animate 20s linear infinite;
        border-radius: 10px;
    }
    .colorido:hover::after
    {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(45deg, #fb0094, #00f, #0f0, #ff0, #f00, #fb0094, #00f, #0f0, #ff0, #f00);
        background-size: 400%;
        /* opacity: 0; */
        filter: blur(20px);
        transition: 0.5s;
        animation: animate 20s linear infinite;
        border-radius: 10px;
    }
    .colorido:hover::before,
    .colorido:hover::after{
        opacity: 1;
        border-radius: 10px;
    }
    @keyframes animate {
        0%
        {
            background-position: 0 0;
        }
        50%
        {
            background-position: 300% 0;
        }
        100%
        {
            background-position: 0 0;
        }
    }
.colorido div {
    position: absolute;
    display: block;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    text-align: center;
    background: #0c0c0c;
    color: #fff;
    transition: 0.5s;
    z-index: 1;
    border-radius: 10px;
}
.colorido:hover div{
    color: rgba(255,255,255, 1);
}
.colorido div::before
{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: rgba(255,255,255,0.1);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}