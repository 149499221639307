* {
    margin: 0;
    padding: 0;
}

:root {
    --TextoDestaque: #0099ff;
    --TextoDestaqueHover: #ff5e00;
    --TextoSelecionado: #00ff00;
    --light-blue: #75aaff;
    --primary-grey: #030518;
    --secondary-grey: #042a01;
    --blackish: #0c0c0d;
    --ProjectBackground: rgba(255, 255, 255, 0.05);
    --borderImgProject: #ff5e00;
    --ProfilePictureColor: linear-gradient(45deg, #f00,#0f0, #00f, #f00,#0f0,  #00f,#0f0,#0f0, #f00);
    --horizontal-gradient: linear-gradient(90deg, #00bbff, #e600ff, #00bbff);
    --BorderSections: #ffffff;
}

::selection {
    background: var(--TextoSelecionado);
    color: #000
}

body {
    font-family: inter, Arial;
    background-color: #09050f;
    color: #fff;
    background: transparent;
    background-image: url('../Images/BGG.jpg');
}
.SubMain {
    backdrop-filter: blur(10px);
    border-radius: 30px;
    box-shadow: #000 0px 2px 4px 0px, #000 0px 2px 50px 0px;
    margin: 30px 10vw;
	filter: drop-shadow(10px 0 5px #000);
}

.section {
    color: #fff;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

@media(max-width:1000px) {
    .section {
        font-size: 20px;
    }

    .SubMain {
        margin: auto 5vw;
    }
}

.hero {
    height: 95vh;
	filter: drop-shadow(10px 0 5px #000);
    background-image: url('../Images/BGJanela.png');
    background-position: bottom;
    background-size: cover;
    backdrop-filter: blur(10px);
}

.hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    filter: blur(10px);
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Fundo semi-transparente */
    z-index: -1;
}  

.hero--content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 19px;
    height: 100%;
    margin: 0 auto;
    text-align: center
}


.hero--highlight {
    color: var(--TextoDestaque);
    display: block
}

.hero-bordaBottom {
    padding-bottom: 40px;
}

.titulosBG {
    background: linear-gradient(#ff0000,#de6868, #f700ff);
    background-clip: text;
    font-size: 28px;
    color: transparent;
    text-transform: uppercase;
}

.projects-title {
    margin: 50px 0 30px 0;
}
.projects--container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    padding-bottom:150px;
    max-width: 1300px;
}

.project {
    width: 30%;
    min-height: 500px;
    padding: 10px;
    border-radius: 16px;
    background: var(--ProjectBackground);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.projectImage {
    height: 250px;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
    border-radius: 16px;
}

.projectImage img {
    height: 100%;
    border-radius: 16px; 
}
.project--body {
    display: flex;
    height: 50%;
    padding-top: 15px;
    flex-direction: column;
    justify-content: space-between;
}

.project--body h3 {
    font-size: 30px;
    padding-bottom: 15px;
    font-weight: 700;
}

.project--body p {
    margin: 0 15px;
    font-size: 20px;
    padding: 15px 0;
    text-align: left;
}

@media(max-width:1500px) {
    .project {
        width: 35%;
    }
    .project--body>h3 {
        font-size: 21px
    }
    .project--body>p {
        font-size: 18px
    }
}

@media(max-width:1000px) {
    .project {
        width: 50%;
    }
    .project--body>h3 {
        font-size: 21px
    }
    .project--body>p {
        font-size: 18px
    }
}

@media(max-width:800px) {
    .project {
        width: 60%;
    }
}

@media(max-width:600px) {
    .project {
        width: 80%;
    }
}

.info {
    text-align: center;
    display: flex;
    padding: 30px 0;
    justify-content: center;
    border-bottom: solid 2px #ffffff30;
}

.info-text>h3 {
    color: var(--TextoDestaque);
    font-size: 29px;
    margin-bottom: 15px;
    font-weight: 600;
    text-align: start;
}

.info-text p span {
    color: var(--light-blue);
    font-weight: bold;
    
}

.info-text p span:hover {
    border-bottom: solid 2px #ffffff;
}

.info-text>p {
    font-size: 22px;
    font-weight: 200;
    text-align: justify;
}
.info-text {
    max-width: 900px;
    margin: 0 30px;
}
.profile-picture {
    width: 200px;
    height: 200px;
}

.profile-picture div {
    margin: 1px;
    border-radius: 10px;
}

.profile-picture img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

@media(max-width:1000px) {
    .info-text>p {
        font-size: 18px
    }
    .info-text>h3 {
        font-size: 22px;
        text-align: center;
    }
}