.Footer {
    position: relative;
    background: #3586ff;
    margin-top: 120px;
    padding: 20px 0 20px;
}

.social_icon{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}

.Footer .social_icon li{
    list-style: none;
}


.Footer .social_icon li a {
    font-size: 2em;
    color: #fff;
    margin: 0 10px;
    display: inline-block;
    transition: 0.5s;
}

.Footer .social_icon li a:hover {
    transform: scale(1.3);
    color: rgb(255, 0, 0);
}

.Footer p {
    color:#fff;
    text-align: center;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 1em;
}

.Footer p span {
    color: var(--BorderSections);
    border-bottom: dotted 2px #ffffff;
}

.Footer p span:hover {
    color: #ff0000;
}
.Footer d {
    color: #ff0000;
}

@media(max-width:600px) {
    .Footer p {
        font-size: 0.7em;
    }

}

.Footer .wave {
    position: absolute;
    top: -100px;
    left: 0;
    width: 100%;
    height: 100px;
    background: url(../Images/wave.png);
    background-size: 1000px 100px;
}

.Footer .wave#wave1 {
    z-index: 1000;
    opacity: 1;
    bottom: 0;
    animation: animateWave 4s linear infinite;
}
.Footer .wave#wave2 {
    z-index: 999;
    opacity: 0.5;
    bottom: 10px;
    animation: animateWave_02 4s linear infinite;
}
.Footer .wave#wave3 {
    z-index: 1000;
    opacity: 0.2;
    bottom: 15px;
    animation: animateWave_03 3s linear infinite;
}
.Footer .wave#wave4 {
    z-index: 999;
    opacity: 0.7;
    bottom: 20px;
    animation: animateWave_04 3s linear infinite;
}

@keyframes animateWave {
    0% {
        background-position-x: 1000px;
    }
    100% {
        background-position-x: 0px;
    }
}
@keyframes animateWave_02 {
    0% {
        background-position-x: 0px;
    }
    
    100% {
        background-position-x: 1000px;
    }
}
@keyframes animateWave_03 {
    0% {
        background-position-x: 0px;
    }
    
    100% {
        background-position-x: 1000px;
    }
}
@keyframes animateWave_04 {
    0% {
        background-position-x: 0px;
    }
    
    100% {
        background-position-x: 1000px;
    }
}